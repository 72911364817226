<script setup lang="ts">
import { toRef } from 'vue'
import VPagination from '@hennge/vue3-pagination'
// Properties and events.
//
const emit = defineEmits(['update'])
const props = defineProps<{
  pages: any
  styleOpts?: string
  modelValue: any | null
}>()
// Main variables.
//
const styleOpts = toRef(props, 'styleOpts')
const pages = toRef(props, 'pages')
// Model.
//
const value = computed({
  get(): number | null {
    return props.modelValue
  },
  set() {},
})
// Functions.
//
function updateHandler(value: number) {
  emit('update', value)
}
</script>
<template>
  <div class="py-5" :class="[styleOpts]">
    <VPagination
      v-model="value"
      :pages="pages"
      :range-size="1"
      active-color="#84288E"
      @update:modelValue="updateHandler"
    />
  </div>
</template>

<style lang="scss">
.Pagination {
  justify-content: center;
  list-style: none;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;

  .DotsHolder {
    padding: 5px 15px;
    display: block;
    width: 50px;
  }

  .PaginationControl {
    border: 1px solid #cbcbcb;
    margin: 0 0 0 -1px;
    max-width: 35px;
    flex: auto;

    svg {
      fill: #cbcbcb;
    }

    &:hover,
    &:focus {
      border-color: #cbcbcb;
      background: #eee;
      cursor: pointer;
      color: #373a3c;
    }

    svg.Control-active {
      fill: #84288e;
    }
  }

  .Page {
    border: 1px solid #cbcbcb;
    margin: 0 0 0 -1px;
    min-height: 35px;
    min-width: 35px;
    padding: 5px;

    &:hover,
    &:focus {
      background: #eee !important;
      border-color: #cbcbcb;
      cursor: pointer;
      color: #373a3c;
    }

    &.Page-active {
      border: none;
      color: #fff;

      &:hover,
      &:focus {
        background: #84288e !important;
        cursor: default;
      }
    }
  }
}
</style>
